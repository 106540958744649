import moment from "moment";
import { ADD_NEW_PURCHASES, PURCHASES_FORM_TOOGLE_LOADING, SHOW_NOTIFICATION } from '../constants';
import { API } from "../constants/api";
import { createCrudActions } from '../utils/crudGenerator';
import { axios } from "../connection/ConnectionHandler";

const formatPurchasess = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.orderName || (item.order?.length > 0 ? item.order[0].name : '')}`,
        value: item._id,
        deliveryDate: item.deliveryDate && moment(item.deliveryDate),
        createdAt: item.createdAt && moment(item.createdAt)
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchPurchasess,
    update: updatePurchases,
    delete: deletePurchasess
} = createCrudActions('PURCHASES', "Compras a proveedores", formatPurchasess);

export const createPurchases = (newPurchases) => {
    return async (dispatch) => {
        dispatch({ type: PURCHASES_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.purchasess.create, newPurchases)
            .then((response) => {
                const { _id } = response.data;
                const purchases = formatPurchasess({ ...newPurchases, _id });
                dispatch({ type: ADD_NEW_PURCHASES, payload: purchases });
                dispatch({ type: PURCHASES_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Satisfacción creada con éxito.",
                    },
                });
                response.data = purchases;
                return response;
            })
            .catch((err) => {
                dispatch({ type: PURCHASES_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};